
export function getFormData(form) {
    if (!form) return false;

    const obj = {};
    const formData = new FormData(form);
    for (var key of formData.keys()) {
        obj[key] = formData.get(key);
    }
    return obj;
}

export default getFormData;
