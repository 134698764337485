/* eslint-env jquery */
import Swal from 'sweetalert2'
import axios from 'axios';
import deepMerge from "./../_shared/deepMerge";
import getFormData from "./../_shared/getFormData";

export default class SiluExport {
    constructor(element, options) {
        this.element = element;
        this.options = deepMerge({}, SiluExport.defaultOptions, options);
        this.init();
    }

    init() {
        this.addEventListener();
    }

    addEventListener() {
        this.eventDeletePages();
        this.eventAddPages();
        this.pageModalOpen();
        this.segmentModalOpen();
        this.segmentListSelect();
        this.downloadExport();
    }

    segmentListSelect() {
        const { selectors } = this.options;
        const segmentList = document.querySelector(selectors.segmentList);
        const segmentEditModal = document.querySelector(selectors.segmentEditModal);
        
        segmentList.addEventListener('change', () => {
            if (!segmentList.value) {
                return false;
            }
            const openerListItem = document.querySelector(`#${segmentEditModal.dataset.openerListId}`);
            const pageId = document.querySelector(`${selectors.pageEditModal} #page_id`);
            const data = {
                ajax_action: 'storeSegmentSelection',
                segment_id: parseInt(segmentList.value),
                order: parseInt(openerListItem.querySelector('span.index').innerHTML.slice(0,-1)) - 1,
                page_id: parseInt(pageId.value)
            };
            axios
                .post(`/app/export/index.php?view=axios`, JSON.stringify(data))
                .then(response => {
                    const { data } = response;
                    const { segment } = data;
                    const segmentAddEditButton = openerListItem.querySelector('button.btn');
                    openerListItem.querySelector('span.label').innerHTML = segment.title;
                    openerListItem.classList.remove('text-muted');
                    segmentAddEditButton.dataset.segmentId = segment.id;
                    segmentAddEditButton.querySelector('i').classList.remove('fa-plus');
                    segmentAddEditButton.querySelector('i').classList.add('fa-pen');
                    $(`#${segmentEditModal.id}`).modal('hide');
                })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Fehler',
                        text: error
                    })
                });
        })
    }

    eventAddPages() {
        const { selectors } = this.options;
        const addPageForm = document.querySelector(selectors.addPage);
        this.parsePageSegments();
        addPageForm.addEventListener('submit', (e) => {
            e.preventDefault();
            const {submitter} = e;
            const closeAfterSaving = submitter.classList.contains(this.options.classes.saveAndClose);
            const modal = addPageForm.closest('.modal');
            const formData = getFormData(addPageForm);
            if (!formData) {
                return false;
            }
            const pageId = formData.page_id || null;
            this.storePage(formData, pageId);
            if (closeAfterSaving) {
                $(`#${modal.id}`).modal('hide');
                setTimeout(() => {
                    location.reload();
                }, 500);
            }
        });
    }

    eventDeletePages() {
        const { selectors } = this.options;
        const deletePageButton = document.querySelectorAll(selectors.deletePage);
        if (!deletePageButton) {
            return false;
        }
        deletePageButton.forEach(button => {
            button.addEventListener('click', () => {
                Swal.fire({
                    title: 'Wirklich löschen?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: `Löschen`,
                    denyButtonText: `Abbrechen`
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios.post('/app/export/index.php?view=axios', {
                            ajax_action: 'deletePage',
                            page_id: button.dataset.pageId
                        }).then(() => {
                            Swal.fire('Gelöscht!', '', 'success').then(() => {
                                location.reload();
                            })
                        })
                        .catch(error => {
                            alert(error);
                            Swal.fire('Fehler', error, 'error')
                        })
                    } else if (result.isDenied) {
                        Swal.fire('Abgebrochen', '', 'info')
                    }
                })
            })
        })
    }

    pageModalOpen() {
        const { selectors, classes } = this.options;
        const pageEditModal = document.querySelector(selectors.pageEditModal);
        $(`#${pageEditModal.id}`).on('show.bs.modal', (e) => {
            const opener = e.relatedTarget;
            const modalTitleEl = pageEditModal.querySelector(selectors.modalTitle);
            modalTitleEl.innerHTML = 'Seite hinzufügen';
            if (!opener.classList.contains(classes.editPage)) {
                this.resetPageEdit();
                return;
            }
            modalTitleEl.innerHTML = 'Seite bearbeiten';
            this.fetchPageData(opener.dataset.pageId);
        })
    }

    resetPageEdit() {
        this.parsePageData();
        this.parsePageSegments();
    }

    segmentModalOpen() {
        const { selectors } = this.options;
        const segmentEditModal = document.querySelector(selectors.segmentEditModal);
        const pageEditModal = document.querySelector(selectors.pageEditModal);
        const pageId = pageEditModal.querySelector('#page_id');
        $(`#${segmentEditModal.id}`).on('show.bs.modal', (e) => {
            const opener = e.relatedTarget;
            const segmentId = parseInt(opener.dataset.segmentId);
            const openerListItem = opener.closest('.js-page-segment-item');
            segmentEditModal.dataset.openerListId = openerListItem.id;

            this.fetchSegmentData(pageId.value, segmentId);
        })
    }

    fetchPageData(pageId) {
        axios
            .get(`/app/export/index.php?view=getPageData&page_id=${pageId}`)
            .then(response => {
                const {data} = response;
                const { pageData, pageSegments} = data;
                this.parsePageData(pageData);
                this.parsePageSegments(pageSegments, pageId);
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Fehler',
                    text: error
                })
            })
    }

    fetchSegmentData(pageId, segmentId = 0) {
        axios
            .get(`/app/export/index.php?view=getSegmentList&page_id=${pageId}`)
            .then(response => {
                const { data } = response;
                this.parseSegmentList(data, segmentId);
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Fehler',
                    text: error
                })
            })
    }

    parseSegmentList(segmentData, segmentId = 0) {
        const { selectors } = this.options;
        const segmentListEl = document.querySelector(selectors.segmentList);
        segmentListEl.innerHTML = '';
        segmentData.forEach(item => {
            // const listEl = document.createElement('option');
            // listEl.value = item.id;
            // listEl.innerHTML = item.event_segment_title
            const selected = item.id == segmentId
            segmentListEl.options[segmentListEl.length] = new Option(item.title, item.id, false, selected);

        })
        // console.log(segmentData);
    }

    parsePageSegments(pageSegments = [], pageId = null) {
        const { selectors, classes } = this.options;
        const pageSegmentsList = document.querySelector(selectors.pageSegments);
        const defaultListLength = parseInt(pageSegmentsList.dataset.defaultLength);

        // 1. ist pagesegments gefüllt?
        // if (pageSegments.length == 0) {
        //     return false;
        // }
        pageSegmentsList.innerHTML = '';
        // 2. foreach listitems
        for (let index = 0; index < defaultListLength; index++) {

            const listEl = document.createElement('li');
            
            const label = pageSegments[index] ? pageSegments[index].title : 'Terminstrecke';
            const segmentAddEditIconClass = pageSegments[index] ? 'fa-pen' : 'fa-plus';
            const segmentId = pageSegments[index] ? pageSegments[index].id : 0;
            const btnVisible = !pageId ? 'd-none' : '';
            listEl.classList.add('segment-list__item', 'js-page-segment-item');
            if (!pageSegments[index]) {
                listEl.classList.add(classes.emptySegmentList);
            }
            listEl.id = `js-segment-list-item-${index}`;
            listEl.innerHTML = `
            <span class="pl-2 mb-2 mr-2 index">${(index+1)}.</span>
            <div class="d-flex justify-content-between align-items-center bg-secondary py-2 px-2 mb-2 flex-fill mr-2">
                <span class="label">${label}</span>
                <button class="btn ${btnVisible}" data-toggle="modal" data-segment-id="${segmentId}" data-target="#segmentEditModal"><i class="fal ${segmentAddEditIconClass} js-add"></i></button>
            </div>
            <div class="sorting flex-column align-items-center justify-content-center mb-2">
                <a href="#" class="js-sort text-dark" data-direction="up" data-order="${index}" data-segment-id="${segmentId}"><i class="fal fa-chevron-up"></i></a>
                <a href="#" class="js-sort text-dark" data-direction="down" data-order="${index}" data-segment-id="${segmentId}"><i class="fal fa-chevron-down"></i></a>
            </div>
            `;
            pageSegmentsList.appendChild(listEl);
            
        }
        this.eventSorting();
    }

    eventSorting() {
        const sortButtons = document.querySelectorAll('.js-sort');
        sortButtons.forEach(button => {
            button.addEventListener('click', () => {
                if (!button.dataset.direction) {
                    return false;
                }
                const pageId = parseInt(document.querySelector('#page_id').value);
                const order = parseInt(button.dataset.order);
                const newOrder = button.dataset.direction == 'down' ? order + 1 : order - 1;
                // 1. axios update sort bla
                axios
                    .post(`/app/export/index.php?view=axios`,
                    {
                        ajax_action: 'updateSorting',
                        segment_id: parseInt(button.dataset.segmentId),
                        order_current: order,
                        order_new: newOrder,
                        page_id: pageId
                    })
                    .then(() => {
                        this.fetchPageData(pageId);
                    })
                    .catch(error => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Sortierfehler',
                            text: error
                        })
                    });
                // 2. fetchPageData
                this.fetchPageData(pageId);
            })
        })
    }

    parsePageData(pageData = {}) {
        const { selectors } = this.options;
        const pageEditModal = document.querySelector(selectors.pageEditModal);

        pageEditModal.querySelector(selectors.inputs.pageTitle).value = pageData.page_title || '';
        pageEditModal.querySelector(selectors.inputs.segmentStartDate).value = pageData.segment_start_date || '';
        pageEditModal.querySelector(selectors.inputs.segmentEndDate).value = pageData.segment_end_date || '';
        pageEditModal.querySelector(selectors.inputs.format).value = pageData.format_id || 1;
        pageEditModal.querySelector(selectors.inputs.pageId).value = pageData.id || null;
    }

    storePage(data, pageId = null) {
        data.ajax_action = !pageId ? 'createPage' : 'storePage';
        axios
            .post(
                `/app/export/index.php?view=axios`,
                JSON.stringify(data)
            )
            .then(() => {
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Fehler (storePage)',
                    text: error
                })
            });
    }

    downloadExport() {
        const { selectors, classes } = this.options;
        const downloadButtons = document.querySelectorAll(selectors.downloadExport);

        downloadButtons.forEach(button => {
            button.addEventListener('click', () => {
                const wrapper = button.closest('.modal-footer');
                const spinner = wrapper.querySelector('.spinner');
                const download = wrapper.querySelector('.download');
                const downloadBtn = download.querySelector('a');
                downloadButtons.forEach(btn => btn.classList.add(classes.hide));
                spinner.classList.remove(classes.hide);

                // 1. send request to php
                // 2. php verarbeitet req
                axios
                    .post(
                        '/app/export/index.php?view=axios',
                        {
                            ajax_action: 'downloadExport',
                            type: button.dataset.type
                        }
                    )
                    // 3. success: return persitenttoken + (jpg / pdf)
                    .then(result => {
                        // 4. show download button with url
                        const { data } = result;
                        const url = data;
                        downloadBtn.href = url;
                        spinner.classList.add(classes.hide);
                        download.classList.remove(classes.hide);
                    })
                    .catch(error => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Fehler (Download)',
                            text: error
                        })
                    })
                
            });
        });
    }
}

SiluExport.defaultOptions = {
    name: "SiluExport",
    selectors: {
        addPage: '.js-add-page',
        deletePage: '.js-delete-page',
        pageEditModal: '#pageEditModal',
        segmentEditModal: '#segmentEditModal',
        segmentList: '.js-segment-list',
        modalTitle: '.modal-title',
        pageSegments: '.js-page-segment-list',
        pageSegmentsItems: '.js-page-segment-item',
        downloadExport: '.js-download-export',
        inputs: {
            combinationText: '.js-save-combination',
            pageTitle: '#page_title',
            segmentStartDate: '#segment_start_date',
            segmentEndDate: '#segment_end_date',
            format: '#format',
            pageId: '#page_id'
        }
    },
    classes: {
        saveAndClose: 'js-save-close',
        editPage: 'js-edit-page',
        hide: 'd-none',
        emptySegmentList: 'text-muted'
    }
}

const dom_elements = document.querySelectorAll('.js-export');

for (let index = 0; index < dom_elements.length; index++) {
    const options = dom_elements[index].dataset.options ?
        JSON.parse(dom_elements[index].dataset.options) :
        {};
    new SiluExport(dom_elements[index], options);
}
